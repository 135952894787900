import { NetworkStatus } from '@apollo/client';
import GridLoader from '@components/Loaders/GridLoader';
import useNotifier from 'hooks/useNotifier';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import {
  CreateItemBatchInput,
  QueryOrder,
} from 'apps/things/app/types/thingsGlobalTypes';
import { RootState } from 'store/epics/userPrefEpics';
import AddItemBatch from '../components/AddItemBatch';
import { itemBatchRoutes } from '../constants';
import { useGetAllItemBatches } from '../queries';

import { getDefaultSequence } from '../../utils';
import useCreateBatch from '../hooks/useCreateBatch';
import { CodeSystemType } from 'apps/things/app/types/thingsGlobalTypes';
const AddItemBatchContainer = () => {
  const notify = useNotifier();
  const history = useNavigate();
  const items = useSelector((store: RootState) => store.items.items);
  const { refetch, networkStatus } = useGetAllItemBatches({
    first: 100,
    queryorder: QueryOrder.DESC,
  });

  const [lastSequence] = useState(
    getDefaultSequence(items.data?.node?.batchNumber)
  );

  const onComplete = async (data: any) => {
    notify({
      status: 'success',
      text: 'Item batch created successfully.',
    });
    await refetch();
    history(itemBatchRoutes.list);
  };
  const { create, options } = useCreateBatch(onComplete);

  const isValidStartingCode = (code: string) => {
    const isNineDigits = /^\d{9}$/.test(code); // Check if it's an integer with 9 digits
    return isNineDigits;
  }

  const isValidSecretKey = (key: string) => {
    const isHex32 = /^[0-9a-fA-F]{32}$/.test(key); // Check if it's a hexadecimal with 32 characters
    return isHex32;
  }
  const handleSubmit = (createItemBatchInput: CreateItemBatchInput) => {
    if (createItemBatchInput.code_gen_type === CodeSystemType.OPENTOKEN) {
      if (createItemBatchInput.secret_key) {
        if (!isValidSecretKey(createItemBatchInput.secret_key)) {
          notify({
            status: 'error',
            text: 'Secret key must be 32 characters hexadecimal.',
          });
          return;
        }
      }
      if (createItemBatchInput.starting_code) {
        if (!isValidStartingCode(createItemBatchInput.starting_code)) {
          notify({
            status: 'error',
            text: 'Starting code must be 9 digits.',
          });
          return;
        }
      }
    }

    create({
      variables: { createItemBatchInput },
    });
  };

  if (networkStatus === NetworkStatus.refetch) return <GridLoader />;
  return (
    <AddItemBatch
      handleSubmit={handleSubmit}
      isLoading={options.loading}
      lastSequence={lastSequence}
    />
  );
};

export default AddItemBatchContainer;
