import { Grid, InputLabel } from '@material-ui/core';
import { Form, FormikProps } from 'formik';
import _ from 'lodash';
import moment from 'moment';
// @material-ui/icons
import { DatePicker } from '@material-ui/pickers';
// import Datetime from 'react-datetime';
// core components

import GridContainer from '@components/Grid/GridContainer.js';
import GridItem from '@components/Grid/GridItem.js';
import Button from '@components/CustomButtons/Button';
import React, { useMemo, useState } from 'react';
import { itemBatchFields } from '../constants';
import TextInput from '@components/CustomInput/TextInput';
import GoBackHeader from '@components/GoBackHeader/GoBackHeader';
import styles from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js';
import { makeStyles } from '@material-ui/core/styles';
import { defaultProductType, getDefaultDate } from '../../utils';
import { useGetAllItemSKUsQuery } from 'apps/things/app/sku/queries';
import { getBatchNumber } from 'apps/things/app/utils';
import { QueryOrder } from 'apps/things/app/types/thingsGlobalTypes';
import DrawerSidebar from '@components/Drawer/DrawerSidebar';
import { PAGE_LIST_LIMIT } from '@constants';
import OVSForm from '@components/Form/OVSForm';
import GridHalfWrapper from '@components/Grid/GridHalfWrapper';
import useNotifier from 'hooks/useNotifier';
import { ItemBatchContext } from "../context/BatchProvider";
import { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import CustomSelect from '@components/CustomSelect/CustomSelect';
import {CodeSystemType} from 'apps/things/app/types/thingsGlobalTypes'
// @ts-ignore noqa:
const useStyles = makeStyles(styles);
const ItemBatchForm: React.FC<{
  formBag: FormikProps<any>;
  disableLoginButton: boolean;
  actionButtonName?: string;
  lastSequence: string;
}> = (props) => {
  const {
    formBag,
    disableLoginButton,
    actionButtonName = 'Add Item Batch',
    lastSequence,
  } = props;
  const classes = useStyles();
  const notify = useNotifier()
  const [productType, setProductType] = useState(defaultProductType);
  const [date, setDate] = useState(getDefaultDate());
  const [sequence] = useState(lastSequence || '001');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [itemSKUOptions, setItemSKUOptions] = useState<
    { name: string; _id: string }[]
  >([]);
  const [searchCount, setSearchCount] = useState(0)
  const batchNoLabel = `Generate Batch Number`;
  const {
    data: dataItemSKUs,
    loading: itemSKULoading,
    refetch,
  } = useGetAllItemSKUsQuery({
    first: PAGE_LIST_LIMIT,
    queryorder: QueryOrder.DESC,
  });

  const {
    itemBatches,
    refetchItemBatches,
    setSearchQuery,
  } = useContext(ItemBatchContext);
  useEffect(() => {

    let batchNumber = getBatchNumber({
      productType,
      date,
      sequence: '',
    })
    setSearchQuery(batchNumber)

    // eslint-disable-next-line
  }, [productType, date, sequence]);

  const location = useLocation();

  useEffect(() => {
    const route = location.pathname
    const isAddInRoute = route.includes('add');
    if ((itemBatches.length > 0 && isAddInRoute) || (itemBatches.length && isModalOpen)) {
      let str = itemBatches[0]?.node?.batchNumber;
      let lastFourDigits = parseInt(str?.slice(-4) || '');
      lastFourDigits += 1;
      let newLastFourDigits = lastFourDigits.toString();

      // If the new string is less than 3 characters long, pad it with leading zeros
      while (newLastFourDigits.length < 4) {
        newLastFourDigits = '0' + newLastFourDigits;
      }

      let newStr = str?.slice(0, -4) + newLastFourDigits;
      setBatchNumber(newStr)

    } // eslint-disable-next-line
  }, [itemBatches, searchCount])
  useEffect(() => {
    return () => {
      setSearchQuery('')
    };
    // eslint-disable-next-line
  }, []);


  const searchItemSKU = async (search: string) => {
    if (!refetch) {
      return Promise.resolve(
        itemSKUOptions?.map((it) => ({ name: it.name, value: it._id }))
      );
    }
    const refetchData = await refetch({
      first: PAGE_LIST_LIMIT,
      queryorder: QueryOrder.DESC,
      search,
    });

    const items = refetchData?.data?.getAllItemSKUs?.page?.edges?.map(
      (item) => {
        return {
          label: item.node?.skuName || '',
          value: item.node?._id || '',
        };
      }
    );
    return Promise.resolve(items as { value: string; label: string }[]);
  };

  React.useEffect(() => {
    if (!itemSKULoading && dataItemSKUs) {
      const items = dataItemSKUs?.getAllItemSKUs?.page?.edges?.map((item) => {
        return {
          name: item.node?.skuName || '',
          _id: item.node?._id || '',
        };
      });

      setItemSKUOptions(items || []);
    }
    // eslint-disable-next-line
  }, [dataItemSKUs]);

  const getProductType = (itemSKUId: string) => {
    const item = _.find(dataItemSKUs?.getAllItemSKUs?.page?.edges, function (
      o
    ) {
      return o?.node?._id === itemSKUId;
    });

    if (item?.node) {
      setProductType(item?.node?.productBase || '');
      formBag.setFieldValue(
        itemBatchFields.batchNumber,
        getBatchNumber({
          productType: item?.node?.productBase || '',
          date,
          sequence,
        })
      );
    }
  };

  const setBatchNumber = (batchNumber: string) => {
    formBag.setFieldValue(itemBatchFields.batchNumber, batchNumber);
  };
  const [_date, changeDate] = useState(new Date());
  const formFieldsData = useMemo(
    () => [
      {
        name: '',
        fields: [
          {
            type: 'select-async',
            name: itemBatchFields.itemSKUId,
            label: 'Item SKU',
            options: itemSKUOptions,
            onChange: (e: any) => {
              formBag.setFieldValue(itemBatchFields.itemSKUId, e);
              getProductType(e);
              setSearchCount((prev) => prev + 1)
            },
            value: formBag.values.itemSKUId || '',
            searchPromise: searchItemSKU as (
              skuName: string
            ) => Promise<{ value: string; label: string }[]>,
          },
        ],
      },
    ], // eslint-disable-next-line
    [formBag.values, itemSKUOptions]
  );

  const algorithmOptions = [
    {
    _id: CodeSystemType.ACP1,
    name: CodeSystemType.ACP1
  }, 
  {
    _id: CodeSystemType.ACP2,
    name: CodeSystemType.ACP2
  },
  {
    _id: CodeSystemType.OPENTOKEN,
    name: CodeSystemType.OPENTOKEN
  }]

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <GoBackHeader title={actionButtonName} />
        <Form>
          <GridContainer>
            <OVSForm formFieldsData={formFieldsData} />

            <GridHalfWrapper>
              <TextInput
                label='Batch Number'
                name={itemBatchFields.batchNumber}
                value={
                  formBag.values.batchNumber ||
                  getBatchNumber({ productType, date, sequence })
                }
                readOnly
                onClick={() => { setIsModalOpen(true); }}
              />
            </GridHalfWrapper>
            <GridHalfWrapper>
              <TextInput
                label='Description'
                name={itemBatchFields.description}
                multiline
              />
            </GridHalfWrapper>
            <GridHalfWrapper>
              <CustomSelect
                selectOptions={algorithmOptions}
                // loading={loading}
                onChange={(e: any) => {
                  formBag.setFieldValue(itemBatchFields.algorithmType, e.target.value);
                }}
                label={'Select Algorithm Type'}
                value={formBag.values.algorithmType || ''}
                name={itemBatchFields.algorithmType}
              />
            </GridHalfWrapper>
            {formBag.values.algorithmType === CodeSystemType.OPENTOKEN && (
              <>
                <GridHalfWrapper>
                  <TextInput
                    label='Secret Key'
                    name={itemBatchFields.secretKey}
                    multiline
                  />
                </GridHalfWrapper>
                <GridHalfWrapper>
                  <TextInput
                    label='Starting Code'
                    name={itemBatchFields.startingCode}
                    multiline
                  />
                </GridHalfWrapper>
              </>
            )}
          </GridContainer>
          <Grid>
            <Button
              color='primary'
              onClick={() => {
                setSearchQuery('')
                refetchItemBatches!()
                formBag.submitForm();
              }}
              disabled={disableLoginButton}
            >
              Save
            </Button>
          </Grid>
        </Form>
        <DrawerSidebar
          isModalOpen={isModalOpen}
          toggleModal={setIsModalOpen}
          title={batchNoLabel}
          styles={{ maxWidth: '370px' }}
        >
          <GridContainer>
            <GridItem sm={12}>
              <InputLabel
                htmlFor='simple-select'
                className={classes.selectLabel}
              >
                <br />
                <br />
                {formBag.values.batchNumber}
              </InputLabel>
            </GridItem>
            <GridItem sm={12}>
              <TextInput
                name='undefined'
                label='Product Base'
                value={productType || ''}
                onChange={(e: any) => {
                  setProductType(e.target.value)
                  if (e.target.value?.length > 4) {
                    return notify({
                      text: 'Product Base should be less than four.',
                      status: 'error',
                    });
                  }
                  setProductType(e.target.value);
                  setBatchNumber(
                    getBatchNumber({
                      productType: e.target.value,
                      date,
                      sequence,
                    })
                  );
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12}>
              <InputLabel
                htmlFor='simple-select'
                className={classes.selectLabel}
                id='label-left'
              >
                Date {`<YYMMDD>`}
              </InputLabel>
              <DatePicker
                autoOk
                variant='static'
                format='yymm'
                value={_date}
                // @ts-ignore
                onChange={(date) => {
                  const newDate = moment(date || '')?.format('YYMM');
                  setDate(newDate);
                  changeDate(date || new Date());
                  setBatchNumber(
                    getBatchNumber({
                      productType,
                      date: newDate,
                      sequence,
                    })
                  );
                  setSearchCount((prev) => prev + 1)
                }}
              />
              {/* <Datetime
                timeFormat={false}
                dateFormat='YY-MM-DD'
                // value={date}
                onChange={(moment) => {
                  if (typeof moment === 'object') {
                    //@ts-ignore
                    setDate(moment.format('YYMM'));
                    setBatchNumber(
                      getBatchNumber({
                        productType,
                        date: moment.format('YYMM'),
                        sequence,
                      })
                    );
                    formBag.setFieldValue(
                      itemBatchFields.batchDate,
                      moment.format()
                    );
                  }
                }}
                inputProps={{ placeholder: 'Date Picker Here' }}
              /> */}
            </GridItem>
          </GridContainer>
        </DrawerSidebar>
      </GridItem>
    </GridContainer>
  );
};

export default ItemBatchForm;
