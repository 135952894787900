import ActionModal from "@components/Modal/ActionModal";
import { LinearProgress } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import useNotifier from "hooks/useNotifier";
import React, { useContext, useEffect, useState } from "react";
import { useGetAllItemsforItemBatchInDescendingOrderQuery } from "../../queries";
import { GetAllItemsforItemBatchInDescendingOrder_getAllItemsforItemBatchInDescendingOrder_page_edges } from "../../types/GetAllItemsforItemBatchInDescendingOrder";
import useBatchInitialization from "../../hooks/useBatchInitialization";
import { ItemContext } from "apps/things/app/item/context/ItemProvider";
import { CodeSystemType } from "apps/things/app/types/thingsGlobalTypes";
interface IProps {
  isModalOpen: boolean;
  setIsModalOpen: (isOpen: boolean) => void;
  loading: boolean;
  handleOnClickOky: () => void;
  progress: number;
  itemId: string;
  codeGenType: string | null;
}

const BatchTokenizeModal: React.FC<IProps> = ({
  isModalOpen,
  setIsModalOpen,
  loading,
  itemId,
  codeGenType
}) => {
  // state
  const [batchItems, setBatchItems] = useState<
    GetAllItemsforItemBatchInDescendingOrder_getAllItemsforItemBatchInDescendingOrder_page_edges[]
  >([]);
  const [confirmed, setConfirmed] = useState(false);

  const [complete, setComplete] = useState<number>(0);
  const [failed, setFailed] = useState<number>(0);
  const [progress, setProgress] = useState<number>(0);
  const [initializerLoading, setInitializerLoading] = useState(false);

  const [getItems, { loading: itemsLoading, data, refetch: refetchBatItems }] =
    useGetAllItemsforItemBatchInDescendingOrderQuery({
      itemBatch: itemId,
    });
  const { refetchItems } = useContext(ItemContext);

  // hooks
  const notify = useNotifier();
  const { create: batchInitialize, options: batchInitializeOpts } =
    useBatchInitialization((data) => {
      refetchItems && refetchItems();
      notify({ status: "success", text: "Successfully provisioned" });
    });

  // functions
  const resetState = () => {
    setProgress(0);
    setComplete(0);
    setFailed(0);
  };

  const handleGetItems = async () => {
    resetState();
    if (refetchBatItems) {
      const items = await refetchBatItems({ itemBatch: itemId });
      setBatchItems(
        items?.data?.getAllItemsforItemBatchInDescendingOrder?.page?.edges?.filter(
          (item) => !item?.node?.codeGenerator
        ) || []
      );
    } else {
      await getItems({ variables: { itemBatch: itemId } });
      setBatchItems(
        data?.getAllItemsforItemBatchInDescendingOrder?.page?.edges?.filter(
          (item) => !item?.node?.codeGenerator
        ) || []
      );
    }
  };

  // local hooks
  useEffect(() => {
    if (data) {
      setConfirmed(true);
      setBatchItems(
        data?.getAllItemsforItemBatchInDescendingOrder?.page?.edges?.filter(
          (item) => !item?.node?.codeGenerator
        ) || []
      );
    }
  }, [data]);

  // calculate progress
  useEffect(() => {
    setProgress(((complete + failed) * 100) / batchItems.length); // eslint-disable-next-line
  }, [complete, failed]);

  const handleInitializeCodeGen = async () => {
    setInitializerLoading(true);
    resetState();
    notify({
      text: `Initializing...`,
    });
    await batchInitialize({
      variables: {
        batchInitializeInput: {
          itembatchId: itemId,
          codeGenType: codeGenType as CodeSystemType || null
        },
      },
    });
    notify({
      status: "success",
      text: `items initialized successfully.`,
    });
    setInitializerLoading(false);
  };

  const renderMessage = () => (
    <>
      {!itemsLoading && batchItems.length < 1 && (
        <div>Are you sure you want to tokenize items in this batch?</div>
      )}
      {!itemsLoading && batchItems.length > 0 && (
        <div>
          <h5> Generate tokens for item(s)</h5>

          
        </div>
      )}
      {itemsLoading && <div>Loading batch items...</div>}
    </>
  );

  return (
    <ActionModal
      isModalOpen={isModalOpen}
      toggleModal={setIsModalOpen}
      handleOnClickOkey={confirmed ? handleInitializeCodeGen : handleGetItems}
      handleOnClickCancel={() => setIsModalOpen(false)}
      okText={
        initializerLoading
          ? "Generating..."
          : confirmed
          ? "Generate"
          : "Confirm"
      }
      loading={
        loading ||
        itemsLoading ||
        batchInitializeOpts.loading ||
        initializerLoading
      }
    >
      <div className="progress-bar">
        <LinearProgress
          variant="determinate"
          value={progress}
          color="primary"
          // @ts-ignore
          thickness={4}
        />
      </div>
      {!confirmed && <>{renderMessage()}</>}
      {confirmed && batchItems.length > 0 && <>{renderMessage()}</>}
      {confirmed && batchItems.length < 1 && (
        <Alert severity="info">All items has been initialized.</Alert>
      )}
    </ActionModal>
  );
};

export default BatchTokenizeModal;
